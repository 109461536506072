import React, { useState } from 'react'
import { prepareSubmit } from '../utilis/prepareSubmit'
import { submitForm } from '../hubspot/submitForm'

const Final = ({ result }: { result: any }) => {

    const [loading, setLoading] = useState(false)
    const [status, setStatus] = useState('')

    const handleSubmit = async () => {
        await submitForm(prepareSubmit(result), setLoading, setStatus)
    }

    return <div className="section-wrapper">
        <div className='section-container-black'>
            <div className="solutions-wrapper">
                <div className='d-flex flex-column justify-content-center bd-highlight w-100 mt-2'>
                    <h1 className='d-flex justify-content-center align-items-center mb-4'> Thank you for completing our publisher onboarding questionnaire!</h1>
                </div>
            </div>
            <h2 className='d-flex justify-content-center align-items-center my-4'> To submit your answers to the Anonymised team, please click the submit button below</h2>
            <div className='d-flex flex-column justify-content-center align-items-center h-100'>
                {loading
                    ? <>
                        <div className="lds-ring"><div></div><div></div><div></div><div></div></div>
                        <h3 className='text-center text-teal mt-4'>Submitting your answers...</h3>
                    </>
                    : status.length > 0
                        ? <div className='d-flex flex-column align-items-center'>
                            {status.includes('error') && <button className='submit-btn' onClick={handleSubmit} style={{ border: 'none', background: 'transparent', color: '#01f5d4', fontSize: '32px' }}>Submit</button>}
                            <h3 className='text-center text-teal mt-4'>{status}</h3>
                        </div>
                        : <div className='d-flex justify-content-center align-items-start mb-5'>
                            <button className='submit-btn' onClick={handleSubmit} style={{ border: 'none', background: 'transparent', color: '#01f5d4', fontSize: '32px' }}>Submit</button>
                        </div>}

            </div>
        </div>
    </div>
}

export default Final
