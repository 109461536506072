import React, { useEffect, useMemo, useState } from 'react'
import { FormFeedback, FormGroup, Input, Label } from 'reactstrap'
import { Info } from 'react-feather'
import { LocalStorageKeys } from '../../../app/types/LocalStorageKeys'

const GTMPrebid = ({ result, handleResult, setIsValidStep }: { result: any, handleResult: (result: any, key: LocalStorageKeys) => void, setIsValidStep: (value: boolean) => void }) => {


    const [prebidAutomonomy, setPrebidAutomonomy] = useState(result[LocalStorageKeys.PrebidAutomony]
        ? result[LocalStorageKeys.PrebidAutomony].isAutonomous
        : true)

    const [thirdParty, setThirdParty] = useState(result[LocalStorageKeys.PrebidAutomony]
        ? { name: result[LocalStorageKeys.PrebidAutomony].name, email: result[LocalStorageKeys.PrebidAutomony].contactEmail, contactName: result[LocalStorageKeys.PrebidAutomony].contactName }
        : { name: '', contactName: '', contactEmail: '' })

    const step = useMemo(() => +window.location.hash.split('step')[1], [window.location.hash])

    const handleValidate = () => {

        if (step === 6) {
            return result[LocalStorageKeys.GTM] === 'true'
                ? true
                : (result[LocalStorageKeys.TMS]?.found === 'true' && result[LocalStorageKeys.TMS]?.name?.length > 0) || result[LocalStorageKeys.TMS]?.found === 'false'
        }

        if (result[LocalStorageKeys.Prebid] && result[LocalStorageKeys.Prebid] === 'noPrebid' && step === 6) {
            return result[LocalStorageKeys.GTM] === 'true'
                ? true
                : (result[LocalStorageKeys.TMS]?.found === 'true' && result[LocalStorageKeys.TMS]?.name?.length > 0) || result[LocalStorageKeys.TMS]?.found === 'false'
        }
        if (result[LocalStorageKeys.Prebid] && result[LocalStorageKeys.Prebid] !== 'noPrebid' && step === 6) {

            return result[LocalStorageKeys.GTM]?.length > 0 && result[LocalStorageKeys.PrebidAutomony]?.isAutonomous
                ? result[LocalStorageKeys.PrebidAutomony]?.isAutonomous
                : /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(thirdParty?.contactEmail) && thirdParty?.contactName.trim() && thirdParty?.name.trim()
        }
    }

    useEffect(() => {
        if (result[LocalStorageKeys.Prebid] && result[LocalStorageKeys.Prebid] === 'noPrebid' && step === 6) {
            setIsValidStep(handleValidate())
        }
        if (result[LocalStorageKeys.Prebid] && result[LocalStorageKeys.Prebid] !== 'noPrebid' && step === 6) {
            setIsValidStep(handleValidate())
        }
    }, [result[LocalStorageKeys.Prebid], result[LocalStorageKeys.GTM], result[LocalStorageKeys.PrebidAutomony], result[LocalStorageKeys.TMS], thirdParty, step])

    useEffect(() => {
        if (result[LocalStorageKeys.Prebid] && result[LocalStorageKeys.Prebid] === 'noPrebid') {
            setThirdParty({ name: '', contactEmail: '', contactName: '' })
            handleResult({ isAutonomous: prebidAutomonomy, thirdPartyName: '', thirdPartyEmail: '' }, LocalStorageKeys.PrebidAutomony)
        }
        if (result[LocalStorageKeys.Prebid] && !result[LocalStorageKeys.PrebidAutomony]) {
            handleResult({ isAutonomous: prebidAutomonomy, thirdPartyName: '', thirdPartyEmail: '' }, LocalStorageKeys.PrebidAutomony)
        }
    }, [result[LocalStorageKeys.Prebid]])

    const handleGTM = (e: any) => {
        handleResult(e.target.value, LocalStorageKeys.GTM)
    }

    const handlePRebid = (e: any) => {
        handleResult(e.target.value, LocalStorageKeys.Prebid)
    }

    const isCheckedGTM = (value: string) => {
        return result[LocalStorageKeys.GTM] === value
    }

    const isCheckedPrebid = (value: string) => {
        return result[LocalStorageKeys.Prebid] === value
    }

    const handlePrebidAutonomy = (e: any, type: string) => {
        const newValue = { ...thirdParty, [type]: e.target.value }
        const newLocalStorageValue = { ...result[LocalStorageKeys.PrebidAutomony], [type]: e.target.value }

        setThirdParty(newValue)
        handleResult(newLocalStorageValue, LocalStorageKeys.PrebidAutomony)
    }

    const handleAutonomy = (value: boolean) => {
        setPrebidAutomonomy(value)
        handleResult({ isAutonomous: value, name: '', contactName: '', contactEmail: '' }, LocalStorageKeys.PrebidAutomony)
    }

    return <div className="section-wrapper">
        {step === 6 && <div className='section-container-black'>
            <div className="solutions-wrapper">
                <div className='d-flex flex-column justify-content-center bd-highlight w-100 mt-2'>
                    <h1 className='d-flex justify-content-center align-items-center mb-4'>Tell us about your current tag manager and Prebid integrations?</h1>
                </div>
            </div>
            <div className='d-flex flex-column justify-content-center align-items-center h-100'>
                <h6 className='d-flex justify-content-center text-help mb-4'><Info size={20} color='#8f8f8f' className='mx-2' />
                    Do you have Google Tag Manager installed across your domains?
                </h6>
                <div className='d-flex justify-content-center align-items-start mb-5'>
                    <div className='d-flex mr-2'>
                        <Input onChange={(e) => { handleGTM(e) }} checked={isCheckedGTM('true')} type="radio" name={`GTM`} value="true" className='mr-2' />{' '}
                        Yes
                    </div>
                    <div className='d-flex mr-2'>
                        <Input onChange={(e) => { handleGTM(e) }} checked={isCheckedGTM('false')} type="radio" name={`GTM`} value="false" className='mr-2' />{' '}
                        No
                    </div>
                </div>


                <h6 className='d-flex justify-content-center text-help mb-4'><Info size={20} color='#8f8f8f' className='mx-2' />
                    Do you have another tag management system installed?
                </h6>
                <div className='d-flex justify-content-center align-items-start mb-5'>
                    <div className='d-flex mr-2'>
                        <Input onChange={(e) => { handleResult({ found: e.target.value, name: '' }, LocalStorageKeys.TMS) }} checked={result[LocalStorageKeys.TMS]?.found === 'true'} type="radio" name={`AnotherTag`} value="true" className='mr-2' />{' '}
                        Yes
                    </div>
                    <div className='d-flex mr-2'>
                        <Input onChange={(e) => { handleResult({ found: e.target.value, name: '' }, LocalStorageKeys.TMS) }} checked={result[LocalStorageKeys.TMS]?.found === 'false'} type="radio" name={`AnotherTag`} value="false" className='mr-2' />{' '}
                        No
                    </div>
                </div>


                {result[LocalStorageKeys.TMS]?.found === 'true' && <>
                    <h6 className='d-flex justify-content-center text-help mb-4'><Info size={20} color='#8f8f8f' className='mx-2' />
                        Please tell us the name of the tag management system you are using
                    </h6>
                    <div className='d-flex justify-content-center align-items-start mb-5'>
                        <div className='d-flex'>
                            <Input style={{ minWidth: '280px' }} value={result[LocalStorageKeys.TMS]?.name || ''} onChange={(e) => handleResult({ found: 'true', name: e.target.value }, LocalStorageKeys.TMS)} type="text" placeholder="Tag Management System" />
                        </div>
                    </div>
                </>}

                <h6 className='d-flex justify-content-center text-help mb-4'><Info size={20} color='#8f8f8f' className='mx-2' />
                    Do you have client side or server side Prebid installed?
                </h6>
                <div className='d-flex justify-content-center align-items-start mb-5'>
                    <div className='d-flex mr-2'>
                        <Input onChange={(e) => { handlePRebid(e) }} checked={isCheckedPrebid('noPrebid')} type="radio" name={`Prebid`} value="noPrebid" className='mr-2' />{' '}
                        We don’t currently have Prebid installed
                    </div>
                    <div className='d-flex mr-2'>
                        <Input onChange={(e) => { handlePRebid(e) }} checked={isCheckedPrebid('clientPrebid')} type="radio" name={`Prebid`} value="clientPrebid" className='mr-2' />{' '}
                        Yes, client side Prebid
                    </div>
                    <div className='d-flex'>
                        <Input onChange={(e) => { handlePRebid(e) }} checked={isCheckedPrebid('serverPrebid')} type="radio" name={`Prebid`} value="serverPrebid" className='mr-2' />{' '}
                        Yes, server side Prebid
                    </div>
                </div>

                {(result[LocalStorageKeys.Prebid] !== 'noPrebid' && result[LocalStorageKeys.Prebid]) && <div className='d-flex flex-column align-items-center mb-4'>

                    <h6 className='d-flex justify-content-center text-help mb-4'><Info size={20} color='#8f8f8f' className='mx-2' />
                        Are you able to make changes to your Prebid configuration yourself or is your Prebid controlled by a third party?
                    </h6>
                    <div className='d-flex justify-content-center align-items-start mb-5'>
                        <div className='d-flex mr-2'>
                            <Input onChange={() => handleAutonomy(false)}
                                checked={!prebidAutomonomy}
                                type="radio"
                                name={`Autonomy`}
                                value="false"
                                className='mr-2' />{' '}
                            Prebid is controlled by a third party
                        </div>
                        <div className='d-flex mr-2'>
                            <Input onChange={() => handleAutonomy(true)}
                                checked={prebidAutomonomy}
                                type="radio"
                                name={`Autonomy`}
                                value="true"
                                className='mr-2' />{' '}
                            We can make configuration changes ourselves
                        </div>
                    </div>

                    {!prebidAutomonomy && <h6 className='d-flex justify-content-center text-help mb-4'><Info size={20} color='#8f8f8f' className='mx-2' />
                        Please provide the name of the relevant third party and the name and email address of your relevant contact
                    </h6>}

                    {!prebidAutomonomy && <FormGroup className='d-flex flex-column align-items-center mb-4'>
                        <Input type="text"
                            value={thirdParty.name}
                            onChange={(e) => { handlePrebidAutonomy(e, 'name') }}
                            placeholder="Name of third party" invalid={!thirdParty.name.trim()}
                            valid={thirdParty.name.trim()} />
                        <FormFeedback valid={thirdParty.name.trim()}>
                            {thirdParty.name.trim() ? 'Third party name is valid' : 'Please enter valid name'}
                        </FormFeedback>

                        <Input type="text"
                            value={thirdParty.contactName}
                            onChange={(e) => { handlePrebidAutonomy(e, 'contactName') }}
                            placeholder="Contact Name" invalid={!thirdParty.contactName.trim()}
                            valid={thirdParty.contactName.trim()} />
                        <FormFeedback valid={thirdParty.contactName.trim()}>
                            {thirdParty.contactName.trim() ? 'Third party contact name is valid' : 'Please enter valid name'}
                        </FormFeedback>

                        <Input type="text"
                            value={thirdParty.contactEmail}
                            onChange={(e) => { handlePrebidAutonomy(e, 'contactEmail') }}
                            placeholder="Contact E-mail" invalid={!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(thirdParty.contactEmail)}
                            valid={/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(thirdParty.contactEmail)} />
                        <FormFeedback valid={/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(thirdParty.contactEmail)}>
                            {/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(thirdParty.contactEmail) ? 'Third party contact email is valid' : 'Please enter valid email'}
                        </FormFeedback>
                    </FormGroup>}

                </div>}

                {handleValidate() && <div className='d-flex justify-content-center align-items-center align-self-center w-50 text-center text-successful successful-border my-4'>
                    <h5 className='first-uppercase'>
                        GTM & Prebid integration are selected
                    </h5>
                </div>}

            </div>
        </div>}
    </div>
}

export default GTMPrebid