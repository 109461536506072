import { LocalStorageKeys } from "../types/LocalStorageKeys";

type SourceObject = {
  [key: string]: any;
};

type TargetObject = {
  properties: { [key: string]: string };
};

export function prepareSubmit(source: SourceObject): TargetObject {
  const properties: { [key: string]: string } = {};

  for (const key in source) {
    if (key === LocalStorageKeys.CMP) {
      properties[key] = source[key].value
    }
    if (key === LocalStorageKeys.SSPpartners || key === LocalStorageKeys.IAB) {
      properties[key] = Array.isArray(source[key]) ? source[key].map((x: any) => x.value).join(';') : source[key].value;
    }
    if (key === LocalStorageKeys.GTM) {
      properties[key] = source[key]
    }
    if (key === LocalStorageKeys.Demand) {
      properties[key] = source[key] === 'yes' ? 'true' : 'false'
    }
    if (key === LocalStorageKeys.Analytics) {
      properties[key] = source[key].map((x: any) => x.value).join(';')
    }
    if (key === LocalStorageKeys.AnalyticsInstallation || key === LocalStorageKeys.AdvertisersBlacklist || key === LocalStorageKeys.CreativeBlacklist
      || key === LocalStorageKeys.PrebidAutomony || key === LocalStorageKeys.CMPAutonomy || key === LocalStorageKeys.SSPsplit || key === LocalStorageKeys.SSPinventory
      || key === LocalStorageKeys.SSPfavored || key === LocalStorageKeys.Price || key === LocalStorageKeys.Device
      || key === LocalStorageKeys.Geo || key === LocalStorageKeys.TMS || key === LocalStorageKeys.Prebid ||
      key === LocalStorageKeys.Domains) {
      properties[key] = JSON.stringify(source[key]);
    }
  }
  return { properties };
}

