import { error } from 'ajv/dist/vocabularies/applicator/dependencies'
import React, { useEffect, useMemo, useState } from 'react'
import { FormFeedback } from 'reactstrap'
import UploadCSV from './UploadCSV'
import { Info } from 'react-feather'
import { LocalStorageKeys } from '../../../app/types/LocalStorageKeys'


const Domains = ({ result, handleResult, setIsValidStep }: { result: any, handleResult: (result: any, key: LocalStorageKeys) => void, setIsValidStep: (value: boolean) => void }) => {

    const step = useMemo(() => +window.location.hash.split('step')[1], [window.location.hash])

    useEffect(() => {
        if (result[LocalStorageKeys.Domains][0] === '') {
            handleResult([], LocalStorageKeys.Domains)
        }
        if (result[LocalStorageKeys.Domains] && step === 1) {
            setIsValidStep(result[LocalStorageKeys.Domains]?.length > 0)
        }
    }, [result[LocalStorageKeys.Domains], step])

    return <div className="section-wrapper">
        {step === 1 && <div className='section-container-black'>
            <div className="solutions-wrapper">
                <div className='d-flex flex-column justify-content-center bd-highlight w-100 mt-2'>
                    <h1 className='d-flex justify-content-center align-items-center mb-4'>Please provide a list of your domains</h1>
                </div>
            </div>
            <div className='d-flex flex-column justify-content-center align-items-center h-100'>
                <h6 className='d-flex justify-content-center text-help mb-4'><Info size={20} color='#8f8f8f' className='mx-2' />
                    Write 1 or several domains, separated by a comma. No spaces are allowed.
                </h6>
                <textarea className='textarea w-75 p-1' placeholder='example.com,example2.com'
                value={result[LocalStorageKeys.Domains] ? result[LocalStorageKeys.Domains].join(',') : ''}
                    onChange={(e) => handleResult(e.target.value.split(',').map((x) => x.trim()), LocalStorageKeys.Domains)} />
                <h5 className='my-4'>Or</h5>
                <h6 className='d-flex justify-content-center text-help mb-4'><Info size={20} color='#8f8f8f' className='mx-2' />
                    Choose a valid CSV file, with one domain per row. No header is required.
                </h6>
                <UploadCSV result={result} handleResult={handleResult} />
            </div>
            {(result[LocalStorageKeys.Domains].length > 0) && <div className='text-center text-successful successful-border my-4'>
        <h5 className='first-uppercase'>
          Domains are selected
        </h5>
      </div>}
        </div>}
    </div>
}

export default Domains