
import './App.css';
import React from 'react';
import Form from './form/index.tsx'

const App = () => {

  {/* 
      <Header></Header>
      <main>
        <div className='d-flex flex-column justify-content-center text-left align-items-center py-4' style={{  width: '100%' }}>
          <Description></Description>
        </div>
        <Test></Test>
      </main> */}

  return (
    <div className="App black-background">
      <div className="container">
        <div className="row justify-content-center">
            <Form/>
        </div>
      </div>
    </div>
  );
}

export default App;

