import React, { useEffect, useMemo } from 'react'
import { FormGroup, Input } from 'reactstrap'
import { Info } from 'react-feather'
import { LocalStorageKeys } from '../../../app/types/LocalStorageKeys'

const Price = ({ result, handleResult, setIsValidStep }: { result: any, handleResult: (result: any, key: LocalStorageKeys) => void, setIsValidStep: (value: boolean) => void }) => {

    const step = useMemo(() => +window.location.hash.split('step')[1], [window.location.hash])

    useEffect(() => {
        if (step === 10) {
            setIsValidStep(true)
        }
    }, [step])

    return <div className="section-wrapper">
        {step === 10 &&  <div className='section-container-black'>
            <div className="solutions-wrapper">
                <div className='d-flex flex-column justify-content-center bd-highlight w-100 mt-2'>
                    <h1 className='d-flex justify-content-center align-items-center mb-4'>What is your current floor price for standard IAB formats?</h1>
                </div>
            </div>
            <div className='d-flex flex-column justify-content-center align-items-center h-100'>
                <h6 className='d-flex justify-content-center text-help mb-4'><Info size={20} color='#8f8f8f' className='mx-2' />
                    This question is optional. If you do not have a floor price, please leave this blank. 
                </h6>
                <p className='d-flex justify-content-center text-help mb-4'>Type your floor price with the currency</p>
                <div className='d-flex justify-content-center align-items-start mb-5'>
                    <FormGroup className='d-flex flex-column align-items-center mb-4'>
                        <Input type="text"
                            value={result[LocalStorageKeys.Price] || ''}
                            onChange={(e) => { handleResult(e.target.value, LocalStorageKeys.Price) }}
                            placeholder="Your floor price" />
                    </FormGroup>

                </div>

                {result[LocalStorageKeys.Price]?.length > 0 && <div className='d-flex justify-content-center align-items-center align-self-center w-50 text-center text-successful successful-border my-4'>
                    <h5 className='first-uppercase'>
                        Price is entered
                    </h5>
                </div>}

            </div>
        </div>}
    </div>
}

export default Price