import React, { useEffect, useState } from "react";

const Header = ({ activeStep, isValidStep }: { activeStep: number, isValidStep: boolean }) => {

    const [progress, setProgress] = useState(0);

    const step = activeStep ? activeStep + 1 : +window.location.hash.split('step')[1];

    useEffect(() => {
        const calculatePercentage = () => {
            const percentage = step === 1 ? 0 : step / 11 * 100;
            setProgress(percentage);
        };

        calculatePercentage();
    }, [step]);

    return <div className="d-flex flex-column justify-content-center align-items-center" style={{ width: '100vw', zIndex: 10, background: '#171717', position: 'sticky', top: 0 }}>
        <div style={{ width: '80vw', zIndex: 10, background: '#171717' }} className="pt-3">
        <div
            style={{
                width: '100%',
                height: '5px',
                backgroundColor: '#f0f0f0',
                overflow: 'hidden',
            }}
        >
            <div
                style={{
                    width: `${progress}%`,
                    height: '100%',
                    backgroundColor: '#01f5d4',
                    animation: 'progressAnimation 5s linear infinite'
                }}
            />
        </div>
        <div className="d-flex justify-content-between flex-wrap py-3">
            <div>
                <svg className='w-100' width="180" height="16" viewBox="0 0 180 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clipPath="url(#clip0_187_4831)">
                        <path d="M34.051 0.965332V14.8186H30.7351L23.6353 4.46889V14.8186H20.9434V0.965332H24.3766L31.3591 11.1378V0.965332H34.051Z" fill="white" />
                        <path d="M74.1352 0.965332V14.8186H70.8193L63.7195 4.46889V14.8186H61.0276V0.965332H64.4608L71.4433 11.1378V0.965332H74.1352Z" fill="white" />
                        <path d="M93.076 0.965332L87.4775 8.78272V14.8186H84.7856V8.80301L79.1684 0.965332H82.2696L86.1509 6.48715L89.9934 0.965332H93.0747H93.076Z" fill="white" />
                        <path d="M114.981 0.965332V14.8186H112.289V4.52705L108.134 14.8172H104.936L100.8 4.58657V14.8172H98.1078V0.965332H102.068L106.554 12.0671L111.041 0.965332H114.981Z" fill="white" />
                        <path d="M121.575 0.965332H124.267V14.8186H121.575V0.965332Z" fill="white" />
                        <path d="M132.596 10.1666C132.675 11.7695 134 12.6001 136.244 12.6001H136.556C138.663 12.6001 139.657 11.9873 139.657 11.0174C139.657 10.1273 138.936 9.593 137.16 9.2954L135.033 8.93964C131.874 8.38502 130.528 7.31637 130.528 4.86253C130.528 2.66706 132.4 0.766479 136.321 0.766479C140.243 0.766479 142.368 2.50744 142.641 5.3184H139.813C139.637 3.97244 138.409 3.18109 136.477 3.18109H136.165C134.312 3.18109 133.24 3.83446 133.24 4.68532C133.24 5.63493 133.864 6.07051 135.405 6.32753L137.591 6.68329C140.771 7.21762 142.369 8.64204 142.369 10.9187C142.369 13.57 140.244 15.0147 136.42 15.0147C132.304 15.0147 129.847 13.3725 129.768 10.1666H132.596Z" fill="white" />
                        <path d="M47.5493 0.766519C47.5493 0.766519 47.5426 0.766519 47.54 0.766519V3.18113H47.7053C50.3972 3.18113 52.5625 4.98161 52.5625 7.89132C52.5625 10.801 50.3972 12.6015 47.7053 12.6015H47.3933C44.7214 12.6015 42.5175 10.801 42.5175 7.89132H39.8055C39.7469 12.2254 43.1214 15.0553 47.5493 15.0161C51.9572 15.0553 55.3318 12.2457 55.2731 7.89132C55.3318 3.5369 51.9572 0.72729 47.5493 0.766519Z" fill="white" />
                        <path d="M172.856 0.965332H166.322V3.37994H172.662C175.704 3.37994 177.284 4.94369 177.284 7.89263C177.284 10.8416 175.704 12.4053 172.662 12.4053H166.322V14.8199H172.856C177.246 14.8199 179.995 12.2281 179.995 7.89398C179.995 3.55986 177.244 0.968037 172.856 0.968037V0.965332Z" fill="white" />
                        <path d="M151.009 3.37859H153.07H160.391V0.965332H148.317V3.37859H151.009Z" fill="white" />
                        <path d="M151.009 8.90045H155.742H159.221V6.48584H153.07H151.009H148.317V8.90045H151.009Z" fill="white" />
                        <path d="M155.742 12.4026H151.009H148.317V14.8172H160.586V12.4026H155.742Z" fill="white" />
                        <path d="M9.76505 0.965332H6.63848L5.28252 3.79523H8.1611L10.6997 9.15743H5.62384H2.71192L0 14.8172H2.96391L4.48654 11.572H11.8263L12.4463 12.8977L13.3583 14.8172H16.3835L9.76505 0.965332Z" fill="white" />
                    </g>
                    <defs>
                        <clipPath id="clip0_187_4831">
                            <rect width="179.995" height="14.2496" fill="white" transform="translate(0 0.766479)" />
                        </clipPath>
                    </defs>
                </svg>
            </div>
            <h4 className='d-flex justify-content-center text-help'>
                Step <span className={isValidStep ? 'text-teal mx-1' : 'text-help mx-1'}>{step}</span> of 11
            </h4>
        </div>
    </div>
    </div>

}

export default Header;