import React, { useEffect, useMemo, useState } from 'react'
import { Input, Table } from 'reactstrap'
import { Info } from 'react-feather'
import { LocalStorageKeys } from '../../../app/types/LocalStorageKeys'
import Select from 'react-select'

const Analytics = ({ result, handleResult, setIsValidStep }: { result: any, handleResult: (result: any, key: LocalStorageKeys) => void, setIsValidStep: (value: boolean) => void }) => {

    const handleSelectSplt = (data: any[]) => {
        handleResult(data, LocalStorageKeys.Analytics)
    }

    const step = useMemo(() => +window.location.hash.split('step')[1], [window.location.hash])

    useEffect(() => {
        if (result[LocalStorageKeys.Analytics]?.length > 0 && step === 8) {
            setIsValidStep((result[LocalStorageKeys.Analytics]?.length > 0 && result[LocalStorageKeys.AnalyticsInstallation]?.every((x) => {
                return x.managed !== ''
            })))
        }
    }, [result[LocalStorageKeys.Analytics], result[LocalStorageKeys.AnalyticsInstallation], step])

    useEffect(() => {
        if (step === 8) {
            handleResult(result[LocalStorageKeys.Analytics]?.map((x) => {
                return { name: x.value, managed: result[LocalStorageKeys.AnalyticsInstallation]?.find((y: any) => y.name === x.value)?.managed || '' }
            }), LocalStorageKeys.AnalyticsInstallation)
        }
    }, [result[LocalStorageKeys.Analytics], step])

    const sspOptions = useMemo(() => {

        const devices = [
            { label: 'GA360', value: 'GA360' },
            { label: 'GA4', value: 'GA4' },
            { label: 'Adobe Analytics', value: 'Adobe Analytics' },
            { label: 'Matomo/Piwik', value: 'Matomo/Piwik' },
            { label: 'Chartbeat', value: 'Chartbeat' },
            { label: 'Piano Analytics', value: 'Piano Analytics' },
            { label: 'Fathom Analytics', value: 'Fathom Analytics' },
            { value: 'Server logs', label: 'Server logs' }]

        const options = devices
            .map(x => ({ value: `${x.value}`, label: `${x.label}` }))
            .sort((a, b) => a.label.localeCompare(b.label))

        return options
    }, [result[LocalStorageKeys.Analytics]])

    const handleAutonomy = (e: any, cmp: { name: string, managed: string }) => {

        const newResult = result[LocalStorageKeys.AnalyticsInstallation].map((x: { name: string, managed: string }) => {
            return x.name === cmp.name ? { ...x, managed: e.target.value } : x
        })

        handleResult(newResult, LocalStorageKeys.AnalyticsInstallation)

    }

    const isChecked = (cmp: { name: string, managed: string }, value: string) => {
        const obj = result[LocalStorageKeys.AnalyticsInstallation]?.find((x: any) => x.name === cmp.name)
        return obj ? obj.managed === value : false
    }

    const customGetOptionLabel = (option) => {
        return `✍️ Create: ${option}`
    }


    return <div className="section-wrapper">
        {step === 8 && <div className='section-container-black'>
            <div className="solutions-wrapper">
                <div className='d-flex flex-column justify-content-center bd-highlight w-100 mt-2'>
                    <h1 className='d-flex justify-content-center align-items-center mb-4'>Which on-site analytics tools do you currently use?</h1>
                </div>
            </div>
            <div className='d-flex flex-column justify-content-center align-items-center h-100'>
                <h6 className='d-flex justify-content-center text-help mb-4'><Info size={20} color='#8f8f8f' className='mx-2' />
                    Please select the analytics tools you currently use from the list below
                </h6>
                <div className='d-flex flex-wrap justify-content-center align-items-start mb-5'>
                    <Select
                        id={'SSPSplit'}
                        name={'SSPSplit'}
                        placeholder={`Analytics tool`}
                        className='react-select-container mr-2'
                        classNamePrefix='react-select'
                        closeMenuOnSelect={false}
                        onChange={handleSelectSplt}
                        value={result[LocalStorageKeys.Analytics] ? result[LocalStorageKeys.Analytics] : []}
                        menuIsOpen={true}
                        options={sspOptions} isMulti />
                    <p className='mb-0 mt-2 text-help'>You can type other Analytics or Performance tools in select</p>

                </div>

                {result[LocalStorageKeys.AnalyticsInstallation]?.length > 0 && <h6 className='d-flex justify-content-center text-help mb-4'><Info size={20} color='#8f8f8f' className='mx-2' />
                    How are they implemented natively or through GTM - natively or through GTM?
                </h6>}

                {result[LocalStorageKeys.AnalyticsInstallation]?.length > 0 && <Table>
                    <thead>
                        <tr className="table-dark">
                            <th>Analytics</th>
                            <th>Implemented</th>
                        </tr>
                    </thead>
                    <tbody>
                        {result[LocalStorageKeys.AnalyticsInstallation].map((x: { name: string, managed: string }) => {
                            return <tr className="table-dark" key={x.name}>
                                <td>{x.name}</td>
                                <td className='w-50'>
                                    <div className='d-flex justify-content-start'>
                                        <div className='d-flex mr-2'>
                                            <Input onChange={(e) => { handleAutonomy(e, x) }} checked={isChecked(x, 'natively')} type="radio"
                                                name={`managed${x.name}`} value="natively" className='mr-2' />{' '}
                                            Natively
                                        </div>
                                        <div className='d-flex'>
                                            <Input onChange={(e) => { handleAutonomy(e, x) }} checked={isChecked(x, 'tagManager')} type="radio"
                                                name={`managed${x.name}`} value="tagManager" className='mr-2' />{' '}
                                            Handled via Tag Manager
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        })}
                    </tbody>
                </Table>}

                {(result[LocalStorageKeys.Analytics]?.length > 0 && result[LocalStorageKeys.AnalyticsInstallation]?.every((x) => {
                    return x.managed !== ''
                })) && <div className='d-flex justify-content-center align-items-center align-self-center w-50 text-center text-successful successful-border my-4'>
                        <h5 className='first-uppercase'>
                            Analytics is selected
                        </h5>
                    </div>}

            </div>
        </div>}
    </div>
}

export default Analytics