import React, { useEffect, useMemo, useState } from 'react'
import { Input } from 'reactstrap'
import { Info } from 'react-feather'
import { LocalStorageKeys } from '../../../app/types/LocalStorageKeys'

const Demand = ({ result, handleResult, setIsValidStep }: { result: any, handleResult: (result: any, key: LocalStorageKeys) => void, setIsValidStep: (value: boolean) => void }) => {

    const handleDemand = (e: any) => {
        handleResult(e.target.value, LocalStorageKeys.Demand)
    }

    const step = useMemo(() => +window.location.hash.split('step')[1], [window.location.hash])

    useEffect(() => {
        if (result[LocalStorageKeys.Demand] && step === 9) {
            setIsValidStep(!!result[LocalStorageKeys.Demand])
        }
    }, [result[LocalStorageKeys.Demand], step])

    const isChecked = (value: string) => {
        return result[LocalStorageKeys.Demand] === value
    }

    const [hasBlacklist, setHasBlacklist] = useState('')

    return <div className="section-wrapper">
        {step === 9 && <div className='section-container-black'>
            <div className="solutions-wrapper">
                <div className='d-flex flex-column justify-content-center bd-highlight w-100 mt-2'>
                    <h1 className='d-flex justify-content-center align-items-center mb-4'>Do you want Anonymised to bring you demand?</h1>
                </div>
            </div>
            <div className='d-flex flex-column justify-content-center align-items-center h-100'>
                <h6 className='d-flex justify-content-center text-help mb-4'><Info size={20} color='#8f8f8f' className='mx-2' />
                    Please select one of the following options.
                </h6>
                <div className='d-flex justify-content-center align-items-start mb-5'>
                    <div className='d-flex mr-2'>
                        <Input onChange={(e) => { handleDemand(e) }} checked={isChecked('yes')} type="radio"
                            name={`demand`} value="yes" className='mr-2' />{' '}
                        Yes
                    </div>
                    <div className='d-flex'>
                        <Input onChange={(e) => { handleDemand(e) }} checked={isChecked('no')} type="radio"
                            name={`demand`} value="no" className='mr-2' />{' '}
                        No
                    </div>

                </div>

                {result[LocalStorageKeys.Demand] === 'yes' && <>
                    <h6 className='d-flex justify-content-center text-help mb-4'><Info size={20} color='#8f8f8f' className='mx-2' />
                        Are there any advertisers you would like us to place on a “no touch” list?
                    </h6>
                    <div className='d-flex justify-content-center align-items-start mb-5'>
                        <div className='d-flex mr-2'>
                            <Input onChange={(e) => { setHasBlacklist(e.target.value) }} checked={hasBlacklist === 'yes'} type="radio"
                                name={`blackList`} value="yes" className='mr-2' />{' '}
                            Yes
                        </div>
                        <div className='d-flex'>
                            <Input onChange={(e) => { setHasBlacklist(e.target.value) }} checked={hasBlacklist === 'no'} type="radio"
                                name={`blackList`} value="no" className='mr-2' />{' '}
                            No
                        </div>

                    </div>
                </>}

                {hasBlacklist === 'yes' && <div className='d-flex flex-column justify-content-center align-items-center h-100'>
                    <>
                        <h6 className='d-flex justify-content-center text-help mb-4'><Info size={20} color='#8f8f8f' className='mx-2' />
                            Please write the names of the brands you would like placed on your “no touch” list in the box below?
                        </h6>

                        <h6 className='d-flex justify-content-center text-help mb-4'>
                            Write 1 or several names, separated by a comma. Leave this blank if you don't have any.
                        </h6>
                        <textarea className='textarea w-75 p-1' placeholder='blackListed brand'
                            value={result[LocalStorageKeys.AdvertisersBlacklist] ? result[LocalStorageKeys.AdvertisersBlacklist].join(',') : ''}
                            onChange={(e) => handleResult(e.target.value.split(','), LocalStorageKeys.AdvertisersBlacklist)} />
                    </>
                </div>}

                {result[LocalStorageKeys.Demand] && <div className='d-flex justify-content-center align-items-center align-self-center w-50 text-center text-successful successful-border my-4'>
                    <h5 className='first-uppercase'>
                        Demand is selected
                    </h5>
                </div>}

            </div>
        </div>}
    </div>
}

export default Demand