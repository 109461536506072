import React, { useEffect, useMemo, useState } from 'react'
import { FormFeedback, FormGroup, Input, Table } from 'reactstrap'
import { Info } from 'react-feather'
import { LocalStorageKeys } from '../../../app/types/LocalStorageKeys'
import Select from 'react-select'

const CMP = ({ result, handleResult, setIsValidStep }: { result: any, handleResult: (result: any, key: LocalStorageKeys) => void, setIsValidStep: (value: boolean) => void }) => {

    const handleSelectSplt = (data: any[]) => {
        handleResult(data, LocalStorageKeys.CMP)
    }

    const step = useMemo(() => +window.location.hash.split('step')[1], [window.location.hash])

    const handleValidate = () => {
        if (result[LocalStorageKeys.CMP] && result[LocalStorageKeys.CMPAutonomy]?.managed === 'in-house') {
            return true
        }
        else {
            return (/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(result[LocalStorageKeys.CMPAutonomy]?.contactEmail) 
            && result[LocalStorageKeys.CMPAutonomy]?.contactName.trim() && result[LocalStorageKeys.CMPAutonomy]?.thirdPartyName.trim())
        }
    }

    useEffect(() => {
        if (result[LocalStorageKeys.CMP] && step === 7) {
            setIsValidStep(handleValidate())
        }
    }, [result[LocalStorageKeys.CMP], result[LocalStorageKeys.CMPAutonomy], step])

    useEffect(() => {
       if (step === 7 && !result[LocalStorageKeys.CMPAutonomy]) {
        handleResult({ name: result[LocalStorageKeys.CMP]?.value, managed: '', thirdPartyName: '', contactName: '', contactEmail: '' }, LocalStorageKeys.CMPAutonomy)
       }
    }, [result[LocalStorageKeys.CMP], step])

    const sspOptions = useMemo(() => {

        const cmps = [
            { label: "Adbank", value: 'adbank' },
            { label: "AdUnity", value: 'adunity' },
            { label: "Axeptio", value: 'axeptio' },
            { label: "Axeptio for Apps", value: 'axeptioForApps' },
            { label: "Criteo", value: 'criteo' },
            { label: "Cookiebot", value: 'cookiebot' },
            { label: "Crownpeak", value: 'crownpeak' },
            { label: "Commanders Act", value: 'commandersAct' },
            { label: "Didomi", value: 'didomi' },
            { label: "Didomi for Apps", value: 'didomiForApps' },
            { label: "Evidon", value: 'evidon' },
            { label: "Flashtalking", value: 'flashtalking' },
            { label: "Ghostery", value: 'ghostery' },
             { label: 'LiveRamp', value: 'liveramp' },
            { label: "Lotame", value: 'lotame' },
            { label: "OneTrust", value: 'onetrust' },
            { label: "OneTrust PreferenceChoice", value: 'onetrustPreferenceChoice' },
            { label: "Pardot", value: 'pardot' },
            { label: "Piwik PRO", value: 'piwikPRO' },
            { label: "Piwik PRO for Apps", value: 'piwikPROForApps' },
            { label: 'Quantcast', value: 'quantcast' },
            { label: "Sourcepoint", value: 'sourcepoint' },
            { label: "Tealium", value: 'tealium' },
            { label: "TrustArc", value: 'trustarc' },
            { label: "TrustArc for Apps", value: 'trustarcForApps' },
            { label: "TrustArc Preference Manager", value: 'trustarcPreferenceManager' },
            { label: "Trustpilot", value: 'trustpilot' },
            { label: "Usercentrics", value: 'usercentrics' },
            { label: "WireWheel", value: 'wirewheel' },
            { label: "WireWheel for Apps", value: 'wirewheelForApps' }
        ]

        const options = cmps
            .map(x => ({ value: `${x.value}`, label: `${x.label}` }))
            .sort((a, b) => a.label.localeCompare(b.label))

        return options
    }, [result[LocalStorageKeys.CMP]])

    const handleAutonomy = (e: any, cmp: { name: string, managed: string }) => {

        const newResult = { ...result[LocalStorageKeys.CMPAutonomy], managed:  e.target.value }

        handleResult(newResult, LocalStorageKeys.CMPAutonomy)

    }

    const isChecked = (cmp: { name: string, managed: string }, value: string) => {
        const obj = result[LocalStorageKeys.CMPAutonomy]
        return obj ? obj.managed === value : false
    }

    const handleThirdParty = (e: any, type: string) => {
        const newLocalStorageValue = { ...result[LocalStorageKeys.CMPAutonomy], [type]: e.target.value }
        handleResult(newLocalStorageValue, LocalStorageKeys.CMPAutonomy)
    }


    const thirdParty = result[LocalStorageKeys.CMPAutonomy]?.managed === 'thirdparty' ? result[LocalStorageKeys.CMPAutonomy] : null

    return <div className="section-wrapper">
        {step === 7 && <div className='section-container-black'>
            <div className="solutions-wrapper">
                <div className='d-flex flex-column justify-content-center bd-highlight w-100 mt-2'>
                    <h1 className='d-flex justify-content-center align-items-center mb-4'>Which CMP do you currently have integrated?</h1>
                </div>
            </div>
            <div className='d-flex flex-column justify-content-center align-items-center h-100'>
                <h6 className='d-flex justify-content-center text-help mb-4'><Info size={20} color='#8f8f8f' className='mx-2' />
                    Please select your current CMP from the list below
                </h6>
                <div className='d-flex justify-content-center align-items-start mb-5'>
                    <Select
                        id={'SSPSplit'}
                        name={'SSPSplit'}
                        placeholder={`Consent Manager`}
                        className='react-select-container mr-2 pr-2'
                        classNamePrefix='react-select'
                        closeMenuOnSelect={false}
                        onChange={handleSelectSplt}
                        value={result[LocalStorageKeys.CMP] ? result[LocalStorageKeys.CMP] : []}
                        menuIsOpen={true}
                        options={sspOptions} />
                </div>

                {result[LocalStorageKeys.CMP]?.value?.length > 0 && <h6 className='d-flex justify-content-center text-help mb-4'><Info size={20} color='#8f8f8f' className='mx-2' />
                    How CMPs are managed - in hourse or by third party?
                </h6>}

                {result[LocalStorageKeys.CMP]?.value?.length > 0 && <div className='d-flex justify-content-center align-items-start mb-5'>
                    <div className='d-flex mr-2'>
                        <Input onChange={(e) => { handleAutonomy(e, result[LocalStorageKeys.CMPAutonomy]) }} checked={isChecked(result[LocalStorageKeys.CMPAutonomy], 'in-house')} type="radio"
                            name={`managed${result[LocalStorageKeys.CMPAutonomy]?.name}`} value="in-house" className='mr-2' />{' '}
                        In House
                    </div>
                    <div className='d-flex mr-2'>
                        <Input onChange={(e) => { handleAutonomy(e, result[LocalStorageKeys.CMPAutonomy]) }} checked={isChecked(result[LocalStorageKeys.CMPAutonomy], 'thirdparty')} type="radio"
                            name={`managed${result[LocalStorageKeys.CMPAutonomy]?.name}`} value="thirdparty" className='mr-2' />{' '}
                        Third Party
                    </div>
                </div>}

                {(result[LocalStorageKeys.CMPAutonomy] && thirdParty) && <>
                    <h6 className='d-flex justify-content-center text-help mb-4'><Info size={20} color='#8f8f8f' className='mx-2' />
                        Please provide the name of the relevant third party and the name and email address of your relevant contact
                    </h6>

                    <FormGroup className='d-flex flex-column align-items-center mb-4'>
                        <Input type="text"
                            value={thirdParty.thirdPartyName}
                            onChange={(e) => { handleThirdParty(e, 'thirdPartyName') }}
                            placeholder="Name of third party" invalid={!thirdParty.thirdPartyName.trim()}
                            valid={thirdParty.thirdPartyName.trim()} />
                        <FormFeedback valid={thirdParty.thirdPartyName.trim()}>
                            {thirdParty.thirdPartyName.trim() ? 'Third party name is valid' : 'Please enter valid name'}
                        </FormFeedback>

                        <Input type="text"
                            value={thirdParty.contactName}
                            onChange={(e) => { handleThirdParty(e, 'contactName') }}
                            placeholder="Contact Name" invalid={!thirdParty.contactName.trim()}
                            valid={thirdParty.contactName.trim()} />
                        <FormFeedback valid={thirdParty.contactName.trim()}>
                            {thirdParty.contactName.trim() ? 'Third party contact name is valid' : 'Please enter valid name'}
                        </FormFeedback>

                        <Input type="text"
                            value={thirdParty.contactEmail}
                            onChange={(e) => { handleThirdParty(e, 'contactEmail') }}
                            placeholder="Contact E-mail" invalid={!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(thirdParty.contactEmail)}
                            valid={/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(thirdParty.contactEmail)} />
                        <FormFeedback valid={/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(thirdParty.contactEmail)}>
                            {/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(thirdParty.contactEmail) ? 'Third party contact email is valid' : 'Please enter valid email'}
                        </FormFeedback>
                    </FormGroup>
                </>}

                {handleValidate() && <div className='d-flex justify-content-center align-items-center align-self-center w-50 text-center text-successful successful-border my-4'>
                        <h5 className='first-uppercase'>
                            CMP is selected
                        </h5>
                    </div>}

            </div>
        </div>}
    </div>
}

export default CMP