import React, { useEffect, useMemo, useState } from 'react'
import { Info } from 'react-feather'
import { LocalStorageKeys } from '../../../app/types/LocalStorageKeys'

const enum Device {
    Desktop = 'Desktop',
    Mobile = 'Mobile',
    Tablet = 'Tablet',
    Other = 'Other'
}

const DeviceSplit = ({ result, handleResult, setIsValidStep }: { result: any, handleResult: (result: any, key: LocalStorageKeys) => void, setIsValidStep: (value: boolean) => void }) => {

    const [trafficPercentage, setTrafficPercentage] = useState({
        [Device.Desktop]: 0,
        [Device.Mobile]: 0,
        [Device.Tablet]: 0,
        [Device.Other]: 0
    })

    const step = useMemo(() => +window.location.hash.split('step')[1], [window.location.hash])

    const isValid = useMemo(() => {
        const sum = result[LocalStorageKeys.Device] ? result[LocalStorageKeys.Device].reduce((sum, obj) => sum + (obj.value || 0), 0) : 0
        return result[LocalStorageKeys.Device]?.length > 0 && sum === 100
    }, [result[LocalStorageKeys.Device]])

    useEffect(() => {
        if (result[LocalStorageKeys.Device] && step === 3) {
            setIsValidStep(isValid)
        }
    }, [result[LocalStorageKeys.Device], step])

    useEffect(() => {
        if (result[LocalStorageKeys.Device] && result[LocalStorageKeys.Device].length > 0 && Object.values(trafficPercentage).every(x => x === 0)) {
            setTrafficPercentage(result[LocalStorageKeys.Device].reduce((acc, { label, value }) => ({ ...acc, [label]: value }), {}))
        }
    }, [result[LocalStorageKeys.Device]])

    const handleChange = (value: number, type: Device) => {
        const newTrafficPercentage = {
            ...trafficPercentage,
            [type]: +value > 0 ? value : 0
        }
        const data = Object.entries(newTrafficPercentage).map(([key, value]) => ({ label: key, value }))

        setTrafficPercentage(newTrafficPercentage)
        handleResult(data, LocalStorageKeys.Device)
    }

    const validateTrafficPercentage = (newValue: number, oldValue: number) => {

        const sum = result[LocalStorageKeys.Device]
            ? result[LocalStorageKeys.Device].reduce((sum, obj) => sum + (obj.value || 0), 0) + newValue - oldValue
            : 0

        return newValue >= 0 && sum <= 100
    }

    return <div className="section-wrapper">
        {step === 3 && <div className='section-container-black'>
            <div className="solutions-wrapper">
                <div className='d-flex flex-column justify-content-center bd-highlight w-100 mt-2'>
                    <h1 className='d-flex justify-content-center align-items-center mb-4'>What is the average device split of your traffic?</h1>
                    <h6 className='d-flex justify-content-center text-help mb-4'><Info size={20} color='#8f8f8f' className='mx-2' />
                        Please state a % for each device option stated below - e.g Desktop 50%, Mobile 25%
                    </h6>
                </div>
            </div>
            <div className='d-flex flex-column justify-content-center align-items-center h-100'>
                <div className='d-flex justify-content-center align-items-start my-3'>
                    <p className='mb-0 w-100px d-flex align-self-center'>Desktop</p>

                    <div className='d-flex align-items-center'>
                        <input type='number' onChange={(e) => {
                            validateTrafficPercentage(+e.target.value, trafficPercentage[Device.Desktop]) && handleChange(+e.target.value, Device.Desktop)
                        }} value={trafficPercentage[Device.Desktop].toString()} placeholder='30' min={1} max={100} />
                        <span style={{ fontSize: '22px' }} className='mx-2'>%</span>
                    </div>
                </div>

                <div className='d-flex justify-content-center align-items-start my-3'>
                    <p className='mb-0 w-100px  d-flex align-self-center'>Mobile</p>
                    <div className='d-flex align-items-center'>
                        <input type='number' onChange={(e) => {
                            validateTrafficPercentage(+e.target.value, trafficPercentage[Device.Mobile]) && handleChange(+e.target.value, Device.Mobile)
                        }} value={trafficPercentage[Device.Mobile].toString()} placeholder='50' min={1} max={100} />
                        <span style={{ fontSize: '22px' }} className='mx-2'>%</span>
                    </div>
                </div>

                <div className='d-flex justify-content-center align-items-start my-3'>

                    <p className='mb-0 w-100px  d-flex align-self-center'>Tablet</p>

                    <div className='d-flex align-items-center'>
                        <input type='number' onChange={(e) => {
                            validateTrafficPercentage(+e.target.value, trafficPercentage[Device.Tablet]) && handleChange(+e.target.value, Device.Tablet)
                        }} value={trafficPercentage[Device.Tablet].toString()} placeholder='20' min={1} max={100} />
                        <span style={{ fontSize: '22px' }} className='mx-2'>%</span>
                    </div>
                </div>

                <div className='d-flex justify-content-center align-items-start my-3'>

                    <p className='mb-0 w-100px  d-flex align-self-center'>Other</p>

                    <div className='d-flex align-items-center'>
                        <input type='number' onChange={(e) => {
                            validateTrafficPercentage(+e.target.value, trafficPercentage[Device.Other]) && handleChange(+e.target.value, Device.Other)
                        }} value={trafficPercentage[Device.Other].toString()} placeholder='20' min={1} max={100} />
                        <span style={{ fontSize: '22px' }} className='mx-2'>%</span>
                    </div>
                </div>

                {isValid && <div className='d-flex justify-content-center align-items-center align-self-center w-50 text-center text-successful successful-border my-4'>
                    <h5 className='first-uppercase'>
                        Device split is selected
                    </h5>
                </div>}
            </div>
        </div>}
    </div>
}

export default DeviceSplit