export enum LocalStorageKeys {
    Domains = 'list_of_tagged_domains',
    Geo = 'csm__geo_split',
    Device = 'csm__device_split',
    SSPpartners = 'csm__ssp_partners',
    SSPsplit = 'csm__ssp_split',
    SSPinventory = 'csm__ssp_inventory',
    SSPfavored = 'csm__ssp_favored',
    IAB = 'csm__business_verticals',
    GTM = 'csm__gtm_found',
    TMS = 'csm__tms_found',
    Prebid = 'csm__prebid_found',
    PrebidAutomony = 'csm__prebid_autonomy',
    CMP = 'content_management_platform',
    CMPAutonomy = 'csm__cmp_autonomy',
    Analytics = 'monetisation_optimisation_analytics',
    AnalyticsInstallation = 'csm__analytics_installation',
    Demand = 'csm__demand_preference',
    AdvertisersBlacklist = 'csm__advertisers_blacklist',
    CreativeBlacklist = 'csm__creative_blacklist',
    Price = 'csm__iab_floor_price',
    Loading = 'loading',
}