import React, { Fragment, useState, useEffect } from 'react';
import StepWizard from 'react-step-wizard';
import { StepWizardChildProps } from "react-step-wizard";
import './../../app/components/form.css'
import "bootstrap/dist/css/bootstrap.css";
import { Stepper, Step } from "react-form-stepper";
import Navigation from './Navigation';
import Header from './Header';
import { LocalStorageKeys } from '../../app/types/LocalStorageKeys';
import Domains from './Audience Information/Domains';
import GeoSplit from './Audience Information/GeoSplit';
import DeviceSplit from './Audience Information/DeviceSplit';
import SSP from './Audience Information/SSP';
import IAB from './Audience Information/IAB';
import GTMPrebid from './Installation/GTMPrebid';
import CMP from './Installation/CMP';
import Analytics from './Installation/Analytics';
import Demand from './Campaign/Demand';
import Blacklist from './Campaign/Blacklist';
import Price from './Campaign/Price';
import Final from './Final';
import './transitions.css';

const StepForm = () => {

    const [state, updateState] = useState({
        form: {},
        demo: true,
        SW: null,
    });

    const [formResults, setFormResults] = useState(localStorage.getItem('FormData')
        ? JSON.parse(localStorage.getItem('FormData') as string)
        : {
            list_of_tagged_domains: [],
        })

    const handleResult = (result: any, key: LocalStorageKeys) => {
      
        if (key === LocalStorageKeys.SSPpartners) {
            setFormResults({
                ...formResults, [key]: result, 
                [LocalStorageKeys.SSPsplit]: result.map((x) => {
                  
                    return { 
                        label: x.label, 
                        value: formResults[LocalStorageKeys.SSPsplit]?.find((y) => x.label === y.label)?.value ? formResults[LocalStorageKeys.SSPsplit].find((y) => x.label === y.label)?.value : 0 }
                })
            })
        }
        else {
            setFormResults({ ...formResults, [key]: result })
        }
    }

    useEffect(() => {
        localStorage.setItem('FormData', JSON.stringify(formResults))
    }, [JSON.stringify(formResults)])

    const onStepChange = () => {
        setActiveStep(SW.state.activeStep)
    };

    const setInstance = (SW: any) => updateState({
        ...state,
        SW,
    });

    const { SW, demo } = state;
    const [activeStep, setActiveStep] = useState(0);
    const [errors, setErrors] = useState(false);

    const [isValidStep, setIsValidStep] = useState(false)

    useEffect(() => {
        setErrors(false)
        setActiveStep(activeStep)
    }, [formResults])

    const validateSubmit = (e: any) => {
        e.preventDefault()
        handleSubmit(e)
        setActiveStep(activeStep)
        // if (values.length > 0 && values.every(x => typeof (x) === 'number') && values.every((x) => x < 6)) {
        //     handleSubmit(e)
        // }
        // else {
        //     setErrors(true)
        //     setActiveStep(activeStep)
        // }
    }

    const handleSubmit = (event: { preventDefault: () => void; }) => {
        event.preventDefault()
    }

    const reset = (event: { preventDefault: () => void; }) => {
        event.preventDefault()
    }

    return (
        <div style={{ width: '100%' }}>
            <div>

                <div>
                    <div >
                        <div>
                            <div >
                                <form onSubmit={handleSubmit} className='black-background d-flex flex-column justify-content-center align-items-center'>
                                    <Header activeStep={activeStep} isValidStep={isValidStep} />
                                    <StepWizard
                                        onStepChange={onStepChange}
                                        isHashEnabled
                                        instance={setInstance}
                                        transitions={{
                                            enterRight: `animated enterRight`,
                                            enterLeft: `animated enterLeft`,
                                            exitRight: `animated exitRight`,
                                            exitLeft: `animated exitLeft`,
                                            intro: `animated intro`,
                                        }}
                                    >
                                        <Domains result={formResults} handleResult={handleResult} setIsValidStep={setIsValidStep} />
                                        <GeoSplit result={formResults} handleResult={handleResult} setIsValidStep={setIsValidStep} />
                                        <DeviceSplit result={formResults} handleResult={handleResult} setIsValidStep={setIsValidStep} />
                                        <SSP result={formResults} handleResult={handleResult} setIsValidStep={setIsValidStep} />
                                        <IAB result={formResults} handleResult={handleResult} setIsValidStep={setIsValidStep} />
                                        <GTMPrebid result={formResults} handleResult={handleResult} setIsValidStep={setIsValidStep} />
                                        <CMP result={formResults} handleResult={handleResult} setIsValidStep={setIsValidStep} />
                                        <Analytics result={formResults} handleResult={handleResult} setIsValidStep={setIsValidStep} />
                                        <Demand result={formResults} handleResult={handleResult} setIsValidStep={setIsValidStep} />
                                        <Price result={formResults} handleResult={handleResult} setIsValidStep={setIsValidStep} />
                                        <Final result={formResults} />
                                    </StepWizard>
                                    <p className={errors ? 'errors' : 'hidden'}>Please fill all the fields</p>
                                </form>
                            </div>
                        </div>
                    </div>
                    {(demo && SW) && <Navigation SW={SW} reset={reset} submit={validateSubmit} setErrors={setErrors} isValidStep={isValidStep} setIsValidStep={setIsValidStep} />}
                </div>
            </div>

        </div>

    );
};

export default StepForm;