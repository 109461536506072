export const submitForm = async (data: any, setLoading: (value: boolean) => void, setStatus: (value: string) => void) => {
    try {
        const clientId = window.location.pathname;

        setLoading(true);

        const response = await fetch(
            `/proxy/submit/companies${clientId}`,
            {
                method: 'PATCH',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                },
                body: JSON.stringify(data),
            }
        );

        const json = await response.json();
            console.log(json);
        if (json.status === 'error' || json.error) {
            console.error('An error occurred:', json.message);
            setLoading(false);
            setStatus('An error occurred, please try again later');
            throw new Error(json.message);
        }

        setStatus('Thank you!');
        setLoading(false);
        return json;
    } catch (error) {
        // Handle specific error cases
        if (error instanceof SyntaxError) {
            console.error('Error parsing JSON response:', error.message);
            setStatus('Error parsing server response');
        } else if (error instanceof TypeError) {
            console.error('Network error:', error.message);
            setStatus('Network error occurred');
        } else {
            console.error('Unhandled error:', error);
            setStatus('An unexpected error occurred');
        }

        setLoading(false);
        throw error;
    }
};