import React, { useEffect, useMemo, useState } from 'react'
import { Info } from 'react-feather'
import { LocalStorageKeys } from '../../../app/types/LocalStorageKeys'
import Select from 'react-select'
import { locations } from './locations'


const GeoSplit = ({ result, handleResult, setIsValidStep }: { result: any, handleResult: (result: any, key: LocalStorageKeys) => void, setIsValidStep: (value: boolean) => void }) => {

    const step = useMemo(() => +window.location.hash.split('step')[1], [window.location.hash])

    const [trafficPercentage, setTrafficPercentage] = useState(null)

    const isValid = useMemo(() => {
        const sum = result[LocalStorageKeys.Geo] ? result[LocalStorageKeys.Geo].reduce((sum, obj) => sum + (obj.value || 0), 0) : 0
        return result[LocalStorageKeys.Geo]?.length > 0 && sum === 100
    }, [result[LocalStorageKeys.Geo]])

    useEffect(() => {
        if (result[LocalStorageKeys.Geo] && step === 2) {
            setIsValidStep(isValid)
        }
    }, [result[LocalStorageKeys.Geo], step])

    const handleChange = (data: any[]) => {

        const newData = data.map((x) => {
            return {
                label: x.label, value: isNaN(result?.[LocalStorageKeys.Geo]?.find((y) => y.label === x.label)?.value)
                    ? 0
                    : result?.[LocalStorageKeys.Geo]?.find((y) => x.label === y.label)?.value
            }
        })

        handleResult(newData, LocalStorageKeys.Geo)
    }

    const countryOptions = useMemo(() => {
        const options = [...locations, { label: 'Other', value: 'Other'}]
            .map(x => ({ value: x.label, label: `${x.label}` }))
            .sort((a, b) => a.label.localeCompare(b.label))

        return options
    }, [locations])

    const handleTrafficPercentage = (value: number, country: string) => {
        setTrafficPercentage({ ...trafficPercentage, [country]: value })

        const newSlpit = result[LocalStorageKeys.Geo].map((x: any) => {
            return { label: x.label, value: x.label === country ? value : x.value }
        })

        handleResult(newSlpit, LocalStorageKeys.Geo)
    }

    const validateTrafficPercentage = (newValue: number, oldValue: number) => {
       console.log(newValue)
        const sum = result[LocalStorageKeys.Geo].reduce((sum, obj) => sum + (obj.value || 0), 0) + newValue - oldValue

        return newValue >= 0 && sum <= 100
    }

    return <div className="section-wrapper">
        {step === 2 && <div className='section-container-black'>
            <div className="solutions-wrapper">
                <div className='d-flex flex-column justify-content-center bd-highlight w-100 mt-2'>
                    <h1 className='d-flex justify-content-center align-items-center mb-4'>What is the current geographic distribution of your audience</h1>
                    <h6 className='d-flex justify-content-center text-help mb-4'><Info size={20} color='#8f8f8f' className='mx-2' />
                        Please select the countries that your traffic comes from and state its associated percentage - e.g 25% UK, 35% US
                    </h6>
                </div>
            </div>
            <div className='d-flex flex-column justify-content-start align-items-center h-100'>
                <div className='d-flex justify-content-center align-items-start'>
                    <Select
                        id={'GeoSplit'}
                        name={'GeoSplit'}
                        placeholder={`Type a country name`}
                        className='react-select-container'
                        classNamePrefix='react-select'
                        closeMenuOnSelect={false}
                        onChange={handleChange}
                        value={result[LocalStorageKeys.Geo] ? result[LocalStorageKeys.Geo].map((x) => {
                            return { value: x.label, label: x.label }
                        }) : []}
                        menuIsOpen={true}
                        options={countryOptions} isMulti />
                </div>

                <div className='d-flex justify-content-center align-items-start flex-wrap my-4'>

                    {result[LocalStorageKeys.Geo] && result[LocalStorageKeys.Geo].map((x: { label: string, value: number }) => {
                    
                        return <div className='d-flex justify-content-center align-items-start my-3 mx-4' key={x.label}>
                            <p className='mb-0 w-100px  d-flex align-self-center'>{x.label}</p>
                            <div className='d-flex align-items-center'>
                                <input type='number' onChange={(e) => {
                                    validateTrafficPercentage(+e.target.value, x.value) && handleTrafficPercentage(+e.target.value, x.label)
                                }} value={x.value.toString()} placeholder='0' min={1} max={100} />
                                <span style={{ fontSize: '22px' }} className='mx-2'>%</span>
                            </div>
                        </div>
                    })}
                </div>

                {isValid && <div className='d-flex justify-content-center align-items-center align-self-center w-50 text-center text-successful successful-border my-4'>
                    <h5 className='first-uppercase'>
                        Geographic split is selected
                    </h5>
                </div>}
            </div>
        </div>}
    </div>
}

export default GeoSplit