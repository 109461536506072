import React, { Fragment } from "react";
import { ArrowLeft, ArrowRight, RotateCcw, RotateCw } from "react-feather";

const Navigation = ({ SW, reset, submit, setErrors, isValidStep, setIsValidStep }:
    { SW: any, reset: any, submit: any, setErrors: any, isValidStep: boolean, setIsValidStep: (value: boolean) => void }) => (

    <Fragment>
        <div className='d-flex  justify-content-center py-4' >
            <div className="d-flex justify-content-center align-items-center" style={{ width: '100vw', position: 'fixed', bottom: 0, paddingBottom: '5px', zIndex: 10, background: '#171717' }}>
                <div className='d-flex justify-content-end align-items-center'
                    style={{ width: '80vw' }}>
                    <button className={SW.state.activeStep > 0 ? 'animated-secondary' : 'hidden'} onClick={(event) => {
                        setErrors(false)
                        SW.previousStep()
                        setIsValidStep(false)
                    }}><ArrowLeft size={22} /> Previous Step</button>
                    &nbsp;
                    {isValidStep && <button className={SW.state.activeStep < 10 ? 'animated-primary' : 'hidden'}
                        onClick={(event) => {
                            SW.nextStep()
                            setIsValidStep(false)
                        }}><ArrowRight size={22} /> {SW.state.activeStep === 9 ? 'Finish' : 'Next Step'}</button>}
                    &nbsp;
                </div>
            </div>
        </div>
    </Fragment>
);

export default Navigation;