export const categories = [
    { value: 'academicInterests', label: 'Academic interests' },
    { value: 'automotive', label: 'Automotive' },
    { value: 'booksLiterature', label: 'Books & Literature' },
    { value: 'businessFinance', label: 'Business & Finance' },
    { value: 'careers', label: 'Careers' },
    { value: 'education', label: 'Education' },
    { value: 'familyRelationships', label: 'Family & Relationships' },
    { value: 'fineArt', label: 'Fine Art' },
    { value: 'foodDrink', label: 'Food & Drink' },
    { value: 'healthyLiving', label: 'Healthy Living' },
    { value: 'hobbiesInterests', label: 'Hobbies & Interests' },
    { value: 'homeGarden', label: 'Home & Garden' },
    { value: 'movies', label: 'Movies' },
    { value: 'music', label: 'Music' },
    { value: 'newsPolitics', label: 'News & Politics' },
    { value: 'personalFinance', label: 'Personal Finance' },
    { value: 'pets', label: 'Pets' },
    { value: 'popCulture', label: 'Pop Culture' },
    { value: 'realEstate', label: 'Real Estate' },
    { value: 'sports', label: 'Sports' },
    { value: 'styleFashion', label: 'Style & Fashion' },
    { value: 'technologyComputing', label: 'Technology & Computing' },
    { value: 'travel', label: 'Travel' },
    { value: 'tvSeries', label: 'TV Series' },
    { value: 'videoGaming', label: 'Video Gaming' },
  ];
  