import React from "react"
import StepForm from "./StepForm"

const Form = () => {
  return <div className="col-md-12">
    <StepForm/>
  </div>
}

export default Form
