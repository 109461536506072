import React, { useEffect, useMemo, useState } from 'react'
import { FormGroup, Input, Label, Table } from 'reactstrap'
import { Info } from 'react-feather'
import { LocalStorageKeys } from '../../../app/types/LocalStorageKeys'
import Select from 'react-select'
import CreatableSelect from 'react-select/creatable'


const enum SSPartner {
    Criteo = 'Criteo',
    Equativ = 'Equativ',
    Magnite = 'Magnite',
    OpenX = 'OpenX',
    Pubmatic = 'Pubmatic',
    Xandr = 'Xandr'
}

const SSP = ({ result, handleResult, setIsValidStep }: { result: any, handleResult: (result: any, key: LocalStorageKeys) => void, setIsValidStep: (value: boolean) => void }) => {

    const [trafficPercentage, setTrafficPercentage] = useState({
        [SSPartner.Criteo]: 0,
        [SSPartner.Equativ]: 0,
        [SSPartner.Magnite]: 0,
        [SSPartner.OpenX]: 0,
        [SSPartner.Pubmatic]: 0,
        [SSPartner.Xandr]: 0
    })

    const isValid = useMemo(() => {
        const sum = result[LocalStorageKeys.SSPsplit] ? result[LocalStorageKeys.SSPsplit].reduce((sum, obj) => sum + (obj.value || 0), 0) : 0
        return result[LocalStorageKeys.SSPsplit]?.length > 0 && sum === 100
    }, [result[LocalStorageKeys.SSPsplit]])

    const step = useMemo(() => +window.location.hash.split('step')[1], [window.location.hash])

    useEffect(() => {
        if (result[LocalStorageKeys.SSPsplit] && step === 4) {
            setIsValidStep(isValid)
        }
    }, [result[LocalStorageKeys.SSPsplit], result[LocalStorageKeys.SSPfavored], step])

    const handleSelectSplt = (data: any[]) => {
        handleResult(data.map((x) => {
            return { ...x, value: trafficPercentage[x.label] }
        }), LocalStorageKeys.SSPsplit)
    }

    const handleSelectPartner = (data: any[]) => {
        const newData = data.map((x) => {
            return {
                ...x, display_order: 0,
                hidden: false,
                read_only: false
            }
        })
        handleResult(newData, LocalStorageKeys.SSPpartners)
    }
    const adPlatforms = [
        { label: 'Xandr', value: 'appNexus' },
        { label: 'Criteo', value: 'criteo' },
        { label: 'Magnite', value: 'magnite' },
        { label: 'Equativ', value: 'smartAdServer' },
        { label: 'OpenX', value: 'openX' },
        { label: 'PubMatic', value: 'pubmatic' },
        { label: 'Google Ad Manager', value: 'googleAdManager' },
        { label: 'Sovrn', value: 'sovrn' },
        { label: 'Index Exchange', value: 'indexExchange' },
        { label: 'Teads', value: 'teads' },
        { label: 'Vistar Media', value: 'vistarMedia' },
        { label: 'Improve Digital', value: 'improveDigital' },
        { label: 'Media.Net', value: 'media.Net' },
        { label: 'E-Planning', value: 'e-Planning' },
        { label: 'AdMob', value: 'adMob' },
        { label: 'AdColony', value: 'adColony' },
        { label: 'Adform', value: 'adform' },
        { label: 'Verizon Media', value: 'verizonMedia' },
        { label: 'SpotX', value: 'spotX' },
        { label: 'PulsePoint', value: 'pulsePoint' },
        { label: 'Nativo Edge', value: 'nativoEdge' },
        { label: 'GumGum', value: 'gumGum' },
        { label: 'Sharethrough', value: 'sharethrough' },
        { label: 'Amazon Publisher Services', value: 'amazonPublisherServices' },
    ];


    const handleTrafficPercentage = (value: number, ssp: SSPartner) => {
        setTrafficPercentage({ ...trafficPercentage, [ssp]: value })

        const newSlpit = result[LocalStorageKeys.SSPsplit].map((x: any) => {
            return { label: x.label, value: x.label === ssp ? value : x.value }
        })

        handleResult(newSlpit, LocalStorageKeys.SSPsplit)

    }

    const inventoryTypes = [
        { label: 'Display', value: 'Display' },
        { label: 'Video', value: 'Video' },
        { label: 'Mobile', value: 'Mobile' },
        { label: 'Native', value: 'Native' },
        { label: 'Audio', value: 'Audio' },
        { label: 'Social Media', value: 'Social Media' },
    ];

    // const handleInventoryType = (e: any, ssp: string) => {
    //     const currentSSPInventory = result[LocalStorageKeys.SSPinventory] || [];

    //     const updatedInventory = currentSSPInventory.map((x) => {
    //         if (Object.keys(x)[0] === ssp) {
    //             return { [ssp]: e.value };
    //         } else {
    //             return x;
    //         }
    //     });

    //     const index = currentSSPInventory.findIndex((x) => Object.keys(x)[0] === ssp);

    //     if (index === -1) {
    //         updatedInventory.push({ [ssp]: e.value });
    //     }

    //     handleResult(updatedInventory, LocalStorageKeys.SSPinventory);
    // }

    const validateTrafficPercentage = (newValue: number, oldValue: number) => {
        const sum = result[LocalStorageKeys.SSPsplit]
            ? result[LocalStorageKeys.SSPsplit].reduce((sum, obj) => sum + (obj.value || 0), 0) + newValue - oldValue
            : 0

        return newValue >= 0 && sum <= 100
    }

    return <div className="section-wrapper">
        {step === 4 && <div className='section-container-black'>
            <div className="solutions-wrapper">
                <div className='d-flex flex-column justify-content-center bd-highlight w-100 mt-2'>
                    <h1 className='d-flex justify-content-center align-items-center mb-4'>Which SSPs do you currently have integrated?</h1>
                </div>
            </div>
            <div className='d-flex flex-column justify-content-center align-items-center h-100'>
                <h6 className='d-flex justify-content-center text-help mb-4'><Info size={20} color='#8f8f8f' className='mx-2' />
                    Please select the SSPs you currently have integrated and the % of traffic allocated to each
                </h6>
                <div className='d-flex justify-content-center align-items-start'>
                    <Select
                        id={'SSPpartners'}
                        name={'SSPpartners'}
                        placeholder={`Xandr`}
                        className='react-select-container'
                        classNamePrefix='react-select'
                        closeMenuOnSelect={false}
                        onChange={handleSelectPartner}
                        value={result[LocalStorageKeys.SSPpartners] ? result[LocalStorageKeys.SSPpartners] : []}
                        menuIsOpen={true}
                        options={adPlatforms} isMulti />

                </div>

                <div className='d-flex justify-content-center align-items-start flex-wrap my-4'>
                </div>

                <div className='my-2'>
                </div>

                <div className='table-wrapper'>
                    {result[LocalStorageKeys.SSPpartners]?.length > 0 && <Table>
                        <thead>
                            <tr className="table-dark">
                                <th>SSP</th>
                                {/* <th>
                                    Inventory Type
                                </th> */}
                                <th>Traffic</th>
                            </tr>
                        </thead>
                        <tbody>
                            {result[LocalStorageKeys.SSPsplit].map((x: { label: SSPartner, value: number }) => {
                                return <tr className="table-dark" key={x.label}>
                                    <td>{x.label}</td>
                                    {/* <td className='w-50'>
                                        <div className='d-flex justify-content-start'>

                                            <CreatableSelect
                                                id={'SSPinventory'}
                                                name={'SSPinventory'}
                                                placeholder={`Select Inventory Type..`}
                                                className='react-select-container position-absolute'
                                                classNamePrefix='react-select'
                                                closeMenuOnSelect={true}
                                                onChange={(e) => { handleInventoryType(e, x.label) }}
                                                value={
                                                    result[LocalStorageKeys.SSPinventory] &&
                                                        result[LocalStorageKeys.SSPinventory].find((y: any) => Object.keys(y)[0] === x.label)
                                                        ? { label: result[LocalStorageKeys.SSPinventory].find((y: any) => Object.keys(y)[0] === x.label)[x.label], value: result[LocalStorageKeys.SSPinventory].find((y: any) => Object.keys(y)[0] === x.label)[x.label] }
                                                        : { label: 'Select Inventory Type..', value: 'Select Inventory Type..' }
                                                }
                                                styles={{
                                                    container: (provided) => ({
                                                        ...provided,
                                                        backgroundColor: 'var(--dark-light)',
                                                        border: '1px solid var(--dark)',
                                                    }),
                                                    menu: (provided) => ({
                                                        ...provided,
                                                        backgroundColor: 'var(--dark-light)',
                                                        border: '1px solid var(--dark)',
                                                    }),
                                                }}
                                                menuPlacement='auto'
                                                options={inventoryTypes} />
                                        </div>
                                    </td> */}
                                    <td>
                                        <div className='d-flex align-items-center'>
                                            <input type='number' onChange={(e) => {
                                                validateTrafficPercentage(+e.target.value, x.value) && handleTrafficPercentage(+e.target.value, x.label)
                                            }} value={x?.value?.toString()} placeholder='50' min={1} max={100} />
                                            <span style={{ fontSize: '22px' }} className='mx-2'>%</span>
                                        </div>

                                    </td>
                                </tr>
                            })}
                        </tbody>
                    </Table>}
                </div>

                {isValid && <div className='d-flex justify-content-center align-items-center align-self-center w-50 text-center text-successful successful-border my-4'>
                    <h5 className='first-uppercase'>
                        SSP Integration & Inventory are selected
                    </h5>
                </div>}

            </div>
        </div>}
    </div>
}

export default SSP