import React, { ChangeEvent, useEffect, useRef, useState } from 'react'
import { Button, Modal, ModalBody, ModalHeader, UncontrolledTooltip } from 'reactstrap'
import { Info, Upload, X, XCircle } from 'react-feather'
import { LocalStorageKeys } from '../../../app/types/LocalStorageKeys'
import '../../components/form.css'

type InvalidDomain = {
  name: string
  error: string
}

const UploadCSV = ({result, handleResult}: {result: any, handleResult: (result: any, key: LocalStorageKeys) => void}) => {

  const [domains, setDomains] = useState<string[]>([])
  const [invalidDomains, setInvalidDomains] = useState<InvalidDomain[]>([])
  const [error, setError] = useState<string | null>(null)
  const [file, setFile] = useState<File | null>(null)
  const [open, setOpen] = useState<boolean>(false)

  const fileInputRef = useRef<HTMLInputElement>(null)

  useEffect(() => {
    invalidDomains.length > 0 ? setError('Invalid CSV file. Please check the file structure and ensure each row contains a valid domain.') : setError(null)
  }, [invalidDomains.length])

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0]
    if (file) {
      const reader = new FileReader()
      reader.onload = handleFileRead
      reader.readAsText(file)
      setFile(file)
    }
  }

  const handleFileRead = (event: ProgressEvent<FileReader>) => {
    if (event.target?.result) {
      setDomains([])
      setInvalidDomains([])
      setError(null)
      const csvData = event.target.result as string
      const parsedDomains = parseCSV(csvData)

      if (parsedDomains.length > 0) {
        setDomains(parsedDomains)
      }
      else {
        setDomains([])
        setError('Invalid CSV file. Please check the file structure and ensure each row contains a valid domain.')
      }
    }
  }

  const isDuplicateDomain = (domain: string, domainList: string[]) => {
    const regexQuery = /^(https?:\/\/)?(www\.)?([a-zA-Z0-9.-]+\.[a-zA-Z]{2,})$/
    const matches = domain.match(regexQuery)

    if (!matches) {
      return false
    }

    const [, prefix, www, mainDomain] = matches  // eslint-disable-line @typescript-eslint/no-unused-vars
    const domainWithoutPrefix = mainDomain.replace(/^www\./, '')

    for (const existingDomain of domainList) {
      const existingMatches = existingDomain.match(regexQuery)

      if (!existingMatches) {
        continue
      }

      const [, existingPrefix, existingWww, existingMainDomain] = existingMatches // eslint-disable-line @typescript-eslint/no-unused-vars
      const existingDomainWithoutPrefix = existingMainDomain.replace(/^www\./, '')

      if (
        (prefix && existingPrefix && domainWithoutPrefix === existingDomainWithoutPrefix) ||
        (!prefix && !existingPrefix && mainDomain === existingMainDomain) ||
        (!prefix && existingPrefix && domainWithoutPrefix === existingMainDomain) ||
        (prefix && !existingPrefix && mainDomain === existingDomainWithoutPrefix) ||
        (prefix === 'https://' && existingPrefix === 'http://' && domainWithoutPrefix === existingDomainWithoutPrefix) ||
        (prefix === 'http://' && existingPrefix === 'https://' && domainWithoutPrefix === existingDomainWithoutPrefix)
      ) {
        return true
      }
    }

    return false
  }

  const isValidDomain = (domain: string, domains: string[]) => {

    const regexQuery = /^(https?:\/\/)?[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}(\/)?$/

    if (domain === '') {
      setInvalidDomains(prevInvalidDomains => [...prevInvalidDomains, { name: domain, error: 'Empty domain name is not allowed' }])
      return false
    }

    if (!domain.match(regexQuery)) {
      setInvalidDomains(prevInvalidDomains => [...prevInvalidDomains, { name: domain, error: 'Domain name should be a valid URL address' }])
      return false
    }

    if (domain.match(regexQuery) && isDuplicateDomain(domain, [...domains])) {
      setInvalidDomains(prevInvalidDomains => [...prevInvalidDomains, { name: domain, error: 'Duplicate domain names are not allowed' }])
      return false
    }

    return true
  }

  const parseCSV = (csvData: string): string[] => {
    const lines = csvData.split('\n')
    const parsedDomains: string[] = []

    for (const line of lines) {
      const trimmedLine = typeof line === 'string' ? line.trim().toLowerCase() : ''

      if (trimmedLine && isValidDomain(trimmedLine, parsedDomains)) {
        parsedDomains.push(trimmedLine)
        handleResult(parsedDomains, LocalStorageKeys.Domains)
      }
      else if (trimmedLine !== '') {
        handleResult([], LocalStorageKeys.Domains)
        setError('Invalid CSV file. Please check the file structure and ensure each row contains a valid domain.')
      }
    }
    return parsedDomains
  }

  const handleRemoveFile = () => {
    setDomains([])
    setInvalidDomains([])
    setError('')
    setFile(null)
    fileInputRef.current.value = ''
    handleResult([], LocalStorageKeys.Domains)
  }

  const closeBtn = <button className='close' title='Close' onClick={() => { setOpen(false) }}><X /></button>

  return (
    <div>
      <div className='d-flex justify-content-center align-items-center flex-wrap'>
        <input type='file' ref={fileInputRef} accept='.csv' onChange={handleFileChange} name='file-2[]' id='file-2' className={`inputfile inputfile-2`}></input>
        <label htmlFor='file-2' className={`${error ? '' : 'animated-button'} upload-wrapper pb-0`}>
          <Upload size={26} />
          {file ? file.name : 'Choose a CSV file'}
        </label>
        {file && <>
          <div className='mr-3'>
            <XCircle size={16} id='removeFile' className='cursor-pointer mx-2' onClick={handleRemoveFile} />
            <UncontrolledTooltip placement='right' target='removeFile'>
              Remove file
            </UncontrolledTooltip>
          </div>
          <Button className='animated-secondary mx-4' onClick={() => { setOpen(true) }}>Examine domains</Button>
        </>}
      </div>
      {error && <div className='text-center text-danger error-border my-4'>
        <h5 className='first-uppercase'>
          {error}
        </h5>
      </div>}

      <Modal autoFocus={false} isOpen={open} toggle={() => { setOpen(open => !open) }}>
        <ModalHeader close={closeBtn}>Domains</ModalHeader>
        <ModalBody>
          {invalidDomains.length > 0 && <div><strong>{invalidDomains.length} Invalid Domains</strong>
            <ul className='csv-domains-container'>
              {invalidDomains.map((domain, index) => (
                <li className='li-is-invalid' key={`${domain.name}-${index}`}>{domain.name} - <span className='mb-0 text-danger'>{domain.error}</span></li>
              ))}
            </ul>
          </div>}

          {domains.length > 0 && <div><strong>{domains.length} Valid Domains</strong>
            <ul className='csv-domains-container'>
              {domains.map((domain, index) => (
                <li key={`${domain}-${index}`}>{domain}</li>
              ))}
            </ul>
          </div>}
        </ModalBody>
      </Modal>

    </div>
  )
}

export default UploadCSV