import React, { useEffect, useMemo } from 'react'
import { Info } from 'react-feather'
import { LocalStorageKeys } from '../../../app/types/LocalStorageKeys'
import Select from 'react-select'
import { categories } from './categories'


const IAB = ({ result, handleResult, setIsValidStep }: { result: any, handleResult: (result: any, key: LocalStorageKeys) => void, setIsValidStep: (value: boolean) => void }) => {

    const handleChange = (data: any[]) => {
        handleResult(data, LocalStorageKeys.IAB)
    }

    const step = useMemo(() => +window.location.hash.split('step')[1], [window.location.hash])

    useEffect(() => {
        if (result[LocalStorageKeys.IAB] && step === 5) {
            setIsValidStep(result[LocalStorageKeys.IAB]?.length > 2)
        }
    }, [result[LocalStorageKeys.IAB], step])

    const iabOptions = useMemo(() => {

        const options = categories.sort((a, b) => a.label.localeCompare(b.label))

        return result[LocalStorageKeys.IAB]?.length > 2 ? [] : options
    }, [categories, result])

    return <div className="section-wrapper">
        {step === 5 && <div className='section-container-black'>
            <div className="solutions-wrapper">
                <div className='d-flex flex-column justify-content-center bd-highlight w-100 mt-2'>
                    <h1 className='d-flex justify-content-center align-items-center mb-4'>What are your top 3 IAB interest categories?</h1>
                    <h6 className='d-flex justify-content-center text-help mb-4'><Info size={20} color='#8f8f8f' className='mx-2' />
                        Please select your 3 most relevant IAB interest categories from the list below
                    </h6>
                </div>
            </div>
            <div className='d-flex flex-column justify-content-center align-items-center h-100'>
                <div className='d-flex justify-content-center align-items-start'>
                    <Select
                        id={'IAB'}
                        name={'IAB'}
                        placeholder={`Technology & Computing`}
                        className='react-select-container'
                        classNamePrefix='react-select'
                        closeMenuOnSelect={false}
                        onChange={handleChange}
                        value={result[LocalStorageKeys.IAB] ? result[LocalStorageKeys.IAB] : []}
                        menuIsOpen={true}
                        options={iabOptions} isMulti />
                </div>
                {result[LocalStorageKeys.IAB]?.length > 2 && <div className='d-flex justify-content-center align-items-center align-self-center w-50 text-center text-successful successful-border my-4'>
                    <h5 className='first-uppercase'>
                        IAB categories are selected
                    </h5>
                </div>}
            </div>
        </div>}
    </div>
}

export default IAB